@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  background-color: #09152b;
  font-family: "Poppins", sans-serif;
}


.gradient,
.active {
  background: #06a055;
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}
.isActive{
  color: #06a055 !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #06a055;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  background-color: white;
  display: inline-block;
  position: relative;
  list-style: none;
  padding: 10px 18px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #f3f3f3;
  border-color:1px solid 09152b !important;
  color: #09152b;
  border-radius: 5px 5px 0 0;
  outline: none;
  font-weight: 600;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

@layer base {
  body {
    @apply font-secondary leading-8 text-gray-900;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
}
